<template>
  <b-modal
      id="confirmation-voting-modal"
      scrollable
      no-close-on-backdrop
      :hide-footer="conData && conData.waiting"
      hide-header-close
      centered
      ref="confirmation-voting-modal">

    <template v-slot:modal-title>
      <span v-if="conData && conData.waiting">Waiting for confirmation</span>
      <span class="mb-0 text-danger" v-else-if="conData && conData.modalType === 'error'">Rejected</span>
      <span class="mb-0" v-else-if="conData && conData.modalType === 'success'">Success</span>
      <span class="mb-0" v-else-if="conData">{{conData.modalTitle ? conData.modalTitle : 'Confirm vote'}}</span>

      <span class="modal-close-icon" clickable v-on:click="hideModal">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="5.63672" y="16.9502" width="16" height="2" rx="1" transform="rotate(-45 5.63672 16.9502)"
                      fill="#B5B5C3"/>
                <rect x="7.05078" y="5.63574" width="16" height="2" rx="1" transform="rotate(45 7.05078 5.63574)"
                      fill="#B5B5C3"/>
            </svg>
            </span>
    </template>

    <b-overlay :show="conData && conData.waiting"
               :variant="'transparent'"
               :opacity="'0.25'"
               :blur="'1px'"
               rounded="sm"
               v-if="conData">
      <div class="my-3" v-if="conData.modalType === 'error' && conData.errorMessage">{{ conData.errorMessage }}</div>
      <div class="my-3 font-weight-bolder" v-else>
        {{ conData.title }}
      </div>
      <b-row align-v="center" v-if="conData.modalType !== 'error'" no-gutters align-h="center">
        <b-col class="jumbotron text-center font-weight-bolder d-flex justify-content-center my-3">
          <div class="mr-1">Vote</div>
          <div class="text-primary ml-1">{{ conData.voteResult }}
          </div>
        </b-col>
      </b-row>
    </b-overlay>
    <template v-slot:modal-footer v-if="conData && !conData.waiting">
      <div v-if="conData && conData.etherscanURL" class="mr-auto">
        <b-button @click="openEtherscan()"
                  class="px-7 button-stroked button-small">
          View on etherscan
        </b-button>
      </div>
      <b-button variant="gray"
                v-on:click="hideModal"
                class="mr-3">
        Close
      </b-button>
      <b-button variant="primary"
                v-on:click="confirm"
                v-if="conData && conData.modalType === 'confirm'">
        Confirm
      </b-button>
    </template>

  </b-modal>

</template>

<script>
export default {
  name: "ConfirmationVotingModal",
  props: {
    conData: {
      title: String,
      voteResult: String,
      modalType: String,
      proposalTitle: String,
      waiting: Boolean,
      etherscanURL: String
    }
  },

  methods: {
    hideModal() {
      this.$refs['confirmation-voting-modal'].hide();
    },
    confirm() {
      this.$emit('ok', true);
    },
    openEtherscan() {
      window.open(this.conData.etherscanURL || 'https://etherscan.io/', '_blank');
    }
  }
}
</script>

<style scoped lang="scss">

</style>
